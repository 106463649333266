import _ from 'lodash';
import React, { createContext } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useUser from 'src/hooks/use-user';
import useUI from 'src/hooks/use-ui';
import useDispensaryMenuSections from 'src/dispensary/hooks/use-dispensary-menu-sections';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';

import {
  BrandCarousel as BrandSection,
  CategoryCarousel as CategorySection,
  SpecialsCarousel as SpecialsSection,
  SubcategoryCarousel as SubcategorySection,
  StaffPicksCarousel as StaffPicksSection,
  TopSellersCarousel as TopSellersSection,
  MostPurchasedCarousel as MostPurchasedSection,
} from '../carousels/generic-carousels';
import { CarouselConfigurationContext } from '../carousels/carousels.utils';

import { CustomCarousel as CustomSection } from '../carousels/custom-carousels';
import { SpecialOffersCarousel as OffersSection } from '../carousels/offers-carousels';
import { RecentlyViewedCarousel as RecentlyViewedSection } from '../carousels/products-carousels';

export const HomepageCarouselSections = observer(({ dispensary }) => {
  const router = useRouter();
  const flags = useFlags();
  const dispensaryId = dispensary?.id;
  const { id: userId, isLoggedIn } = useUser();
  const { isKiosk } = useUI();
  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const personalizationBuyItAgainEnabled = flags['growth.ads.buy-it-again-carousel.rollout'];
  const disableCarouselLazyLoadingFlag = flags['growth.ecomm.deprecate-lazy-carousels.rollout'] ?? false;
  const isPersonalizedCarouselEnabled = personalizationCarouselPlacement !== 'none';
  const showRecentlyViewedCarousel =
    !isPersonalizedCarouselEnabled &&
    !isKiosk &&
    isLoggedIn &&
    useDispensaryFlag(`rollout.recently-viewed-record-product-view`, dispensaryId);

  const showMostPurchasedProducts =
    isLoggedIn && !isKiosk && !(isPersonalizedCarouselEnabled && personalizationBuyItAgainEnabled);
  const { menuSections } = useDispensaryMenuSections();

  if (!dispensaryId) {
    return null;
  }

  const { hideTitle } = router.query;
  const dispensaryState = dispensary.location?.state;
  const hideSpecials = useHideDiscountFromConsumer(dispensary);

  return (
    <Sections data-testid='homepage-carousel-sections'>
      <CarouselConfigurationContext.Provider value={{ useLazyLoading: !disableCarouselLazyLoadingFlag }}>
        {showRecentlyViewedCarousel && <RecentlyViewedSection dispensaryId={dispensaryId} userId={userId} />}

        {showMostPurchasedProducts && <MostPurchasedSection dispensaryId={dispensaryId} />}

        {_.map(menuSections, (section, index) => {
          if (hideTitle) {
            section = { ...section, hideTitle };
          }

          switch (section.sectionType) {
            case 'BRAND':
              return <BrandSection key={section.id} section={section} sectionIndex={index} />;
            case 'CATEGORY':
              return (
                <CategorySection
                  key={section.id}
                  section={section}
                  sectionIndex={index}
                  dispensaryState={dispensaryState}
                />
              );
            case 'SPECIALS':
              return !hideSpecials && <SpecialsSection key={section.id} section={section} sectionIndex={index} />;
            case 'SUBCATEGORY':
              return (
                <SubcategorySection
                  key={section.id}
                  section={section}
                  sectionIndex={index}
                  dispensaryState={dispensaryState}
                />
              );
            case 'STAFF_PICKS':
              return <StaffPicksSection key={section.id} section={section} sectionIndex={index} />;
            case 'TOP_SELLERS':
              return <TopSellersSection key={section.id} section={section} sectionIndex={index} />;
            case 'CUSTOM':
              return <CustomSection key={section.id} section={section} sectionIndex={index} />;
            case 'OFFERS':
              return <OffersSection key={section.id} section={section} />;
            default:
              return null;
          }
        })}
      </CarouselConfigurationContext.Provider>
    </Sections>
  );
});

const Sections = styled.div``;
